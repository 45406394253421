<template>
    <div class="col-sm-8">
        <e-select
            :id="code"
            :name="code"
            v-model="selected"
            :options="options"
            :searchable="true"
			:label="groupValuesLabel"
			:track-by="uniqueIdentifier"
            :allow-empty="true"
            :show-labels="false"
			:group-label="groupLabel"
			:group-values="groupValues"
            :sortable="false"
        >
        </e-select>

        <i v-if="code == 'Laboratoire'" v-html="getTrad('acte.support_labo')"></i>

        <div class="loader" v-if="isLoading">
            <svg class="fa-pulse" x="0px" y="0px" width="35px" height="35px" viewBox="0 0 35 35" ><path d="M25.33,3.22l1.31-2.83c0,0-3.91-1.09-7.32,2l-0.34-0.04c0.12-0.31,0.25-0.63,0.39-0.97 c-2.09,0.24-4.08,0.7-5.94,1.45C7,4.62,2.28,10.51,2.28,17.5c0,8.4,6.81,15.22,15.22,15.22c4.03,0,7.69-1.57,10.42-4.12l-0.01-0.01 c0.63-0.6,1.02-1.45,1.02-2.38c0-1.83-1.48-3.31-3.31-3.31c-0.88,0-1.68,0.35-2.27,0.9l0,0c-1.53,1.42-3.58,2.29-5.84,2.29 c-4.69,0-8.5-3.76-8.58-8.44c0.02-4.54,3.63-8.23,8.14-8.38c0,0-1.15,3.32,2.44,5.24c0,0,1.44,0.94,2.96,1.01 c1.52,0.06,2.22,0.65,2.22,0.65c1.43,1.6,4.69,4.05,4.69,4.05c1.89-1.1,3.27-3.53,3.27-3.53C32.27,13.59,25.33,3.22,25.33,3.22z  M24.34,7.92c-0.19-0.18-0.37-0.37-0.56-0.55c-0.08-0.08-0.07-0.12,0.02-0.19c0.41-0.3,0.92-0.3,1.31,0.04 c0.23,0.2,0.42,0.45,0.64,0.68C25.4,8.28,24.74,8.29,24.34,7.92z"/></svg>
            {{ $t('global.chargement') }}
        </div>
    </div>
</template>

<script type="text/javascript">
    import Common from '@/assets/js/common.js'
    import Navigation from '@/mixins/Navigation.js'

	export default {
		name: "SelectInput",
        mixins: [Navigation],
        props:[
            'placeholder',
            'values',
            'answer',
            'q_id',
            'answer_given',
            'required',
            'ws',
            'code'
        ],
        data() {
            return {
                options: [],
                values_type: '',
                isLoading: false,
                local_values: null,

                events_tab: {
                    'inputs::resetChecked': this.resetCheck
                }
            }
        },
		mounted() {
            this.init_component()
		},
		methods: {
            async init_component() {
                if(typeof(this.values) == "string") {
                    this.local_values = JSON.parse(this.values)
                }
                else {
                    this.local_values = this.values
                }

                this.values_type = this.local_values.type

                if(this.values_type == "data") {
                    let translated_responses = []
                    this.local_values.data.forEach((option, key) => {
                        if(option.values) {
                            option.values.forEach((val, key) => {
                                const status = Common.translateSeasonMareStatus(val.value)
                                option.values[key].value = status
                            })
                            
                            translated_responses[key] = {
                                groupLabel: option.groupLabel,
                                values: option.values
                            }
                        }
                        else {
                            let label = null
                            if(this.ws !== undefined || this.getTrad('reponses.' + [option]).includes('reponses.')) {
                                label = option
                            }
                            else {
                                label = this.getTrad('reponses.' + [option])
                            }

                            translated_responses.push({
                                id: option,
                                label: label
                            })
                        }
                    })

                    this.options = translated_responses
                }

                this.init_input()
                this.isLoading = false
            },
            init_input() {
                if(this.answer_given && this.answer_given.val !== null) {
                    const value = Array.isArray(this.answer_given.val) ? this.answer_given.val[0] : this.answer_given.val
                    if(typeof(value) == "string") {
                        this.selected = this.options.find(opt => opt.id === value)
                    }
                    else {
                        this.selected = value
                        if(this.selected && this.selected.value) {
                            this.selected.value = Common.translateSeasonMareStatus(this.selected.value)
                        }
                    }
                    
                }
            },
            resetCheck() {
                this.selected = ''
            }
        },
        computed: {
			selected: {
				set(val) {
					this.$emit('update:answer', { key: this.q_id, val: val })
				},
				get() {
					return this.answer.val
				}
			},
            optionTextField() {
                if(this.values_type == 'data') {
                    return 'label'
                }
                return 'actestypereference_label'
            },
            optionValueField() {
                if(this.values_type == 'data') {
                    return 'label'
                }
                return 'actestypereference_label'
			},
			// Si on a un attribut groupLabel ça veut dire qu'on groupe le e-select
			groupLabel() {
				if(this.values.data.length > 0) {
					return this.values.data[0].groupLabel ? 'groupLabel' : undefined
				}

				return undefined
			},
			groupValues() {
				if(this.values.data.length > 0) {
					return this.values.data[0].values ? 'values' : undefined
				}

				return undefined
			},
			groupValuesLabel() {
				if(this.values.data.length > 0) {
					return this.values.data[0].values ? 'value' : 'label'
				}

				return undefined
			},
			uniqueIdentifier() {
				if(this.values.data.length > 0) {
					return this.values.data[0].values ? 'uniqueIdentifier' : 'id'
				}

				return undefined
			}
        }
    }
</script>
